<template>
<div>
    <b-row>
        <b-col cols="12">
            <b-card class="my-card m-0">
                <b-table striped small id="myTables" bordered selectable selected-variant="primary" select-mode="single" responsive="sm" :items="reasonProcessing" :fields="fields" @row-clicked="clicked" @row-dblclicked="editForm" head-row-variant="secondary">
                <template #cell(checkbox)="row">
                    <b-form-checkbox v-model="row.detailsShowing" plain class="vs-checkbox-con" @change="row.toggleDetails">
                    </b-form-checkbox>
                </template>
                <template #cell(is_active)="data">
                    <span v-if="data.item.is_active == true">
                        Активен
                    </span>
                    <span v-else>
                        Не активен
                    </span>
                </template>  
                <template #cell(created_at)="data">
                <span>
                  {{data.item.created_at.substring(0, data.item.created_at.indexOf('T')) + ' ' + data.item.created_at.substring(data.item.created_at.indexOf('T') + 1, data.item.created_at.indexOf('.'))}}
                </span>
              </template>
              <template #cell(updated_at)="data">
                <span>
                  {{data.item.updated_at.substring(0, data.item.updated_at.indexOf('T')) + ' ' + data.item.updated_at.substring(data.item.updated_at.indexOf('T') + 1, data.item.updated_at.indexOf('.'))}}
                </span>
              </template>
            </b-table>
        </b-card>
    </b-col>
    </b-row>
</div>
</template>



<script>
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
export default {
    props: ['reasonProcessing'],
    data(){
        return {
            fields: [ 
                { key: 'checkbox', label: '', thStyle: {width: '50px'}},
                { key: 'id', label: 'Id', sortable: true, thStyle: {width: '50px'}},
                { key: 'name', label: 'Наименование', sortable: true, thStyle: {width: '200px'}},
                { key: 'description', label: 'Описание', sortable: true, thStyle: {width: '200px'}},
                { key: 'created_at', label: 'Создан', sortable: true, thStyle: {width: '200px'}},
                { key: 'updated_at', label: 'Обработан', sortable: true, thStyle: {width: '200px'}},
                { key: 'is_active', label: 'Статус', sortable: true, thStyle: {width: '200px'}}
            ],
        }
    },
    mounted(){
        resizeable()
    },
    methods:{
        editForm(id){
            this.$emit('editForm', id)
        },
        clicked(item, index, event){
            this.$store.state.filterPlus.tableIndex = index
            cellSelect()
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-context.scss';
.my-card .card-body{
    padding: 0px;
}
.my-card{
overflow: auto; 
height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card{
    overflow: auto; 
    height: 530px;
    }
}
table{
    outline: none; 
    table-layout: fixed; 
    -webkit-user-select: none;
    overflow: hidden;
    position: relative;
}
tr, td, th{
    padding: 0;
}
td, th{ 
    width: 100px; 
    white-space: nowrap; 
    overflow: hidden;  
    text-align: left; 
    box-sizing: border-box; 
    min-width: 60px;
    }  
.my-danger{
    background-color: #f9cfcf;
}  
.my-success{
    background-color: #8fe2b4;
} .my-secondary{
    background-color: #dcdddf;
} .my-warning{
    background-color: #ffcd9d;
}   
</style> 
