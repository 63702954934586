<template>
<div>
    <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
        <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div>
        <table-Category-Violation :reasonProcessing="reasonProcessing" @editForm="editForm"/>
    </div>
    <Modal-Category-Violation @refresh="refresh" />
    <Modal-Category-Violation-Edit @refresh="refresh" :id="id"/>
</div>
</template>
<script>
import tableCategoryViolation from '@/views/component/Table/tableCategoryViolation.vue'
import ModalCategoryViolation from '@/views/component/Modal/ModalCategoryViolation/ModalCategoryViolation.vue'
import ModalCategoryViolationEdit from '@/views/component/Modal/ModalCategoryViolation/ModalCategoryViolationEdit.vue'
export default {
    components: {
        tableCategoryViolation,
        ModalCategoryViolation,
        ModalCategoryViolationEdit,
    },
    data(){
        return {
            reasonProcessing: [],
            showPreloader: false,
            id: undefined,
        }
    },
    mounted(){
        this.$store.commit('pageData/setdataCount', null)
        this.refresh()
    },
    methods:{
        refresh(){
            this.showPreloader = true
            this.$http.get(`categoryviolation`)
                .then(res => {
                    this.reasonProcessing = res.data
                    this.$store.commit('pageData/setdataCount', this.reasonProcessing.length)
                    this.showPreloader = false
                    this.$store.commit('REFRESH_DATA', false)
                })
        },
        editForm(id){
            this.id = id
            setTimeout(this.openModal, 0);
        },
        openModal(){
            this.$bvModal.show('categoryViolationEdit')
        },
    },
    computed: {
        fetchingNewData(){
            return this.$store.state.refresh.fetchingNewData
        }
    },
    watch: {
        fetchingNewData(val){
            if (val){
                this.$http
                .get(`${this.$route.name}`)
                .then(res =>{
                    this.tariffs = res.data
                    this.$store.commit('pageData/setdataCount', this.tariffs.length)
                    this.$store.commit('REFRESH_DATA', false)
                })
                }
            }
    }

}
</script>